import * as React from "react";
import PersonalLayout from "../../components/pages/personal/PersonalLayout";
import PersonalBody from "../../components/pages/personal/PersonalBody";
import {Badge, Collapse, Skeleton} from "antd";
import Seo from "../../components/seo";
import styled from "@emotion/styled";
/** @jsx jsx */
import {jsx, css} from "@emotion/react";
import {useEffect, useMemo, useState} from "react";
import {useRequest} from "ahooks";
import jsonRequest from "../../utils/request/jsonRequest";
import auth from "../../utils/auth";
import * as moment from "moment";
import InfiniteScroll from 'react-infinite-scroll-component';

const Title = styled.span`
  line-height: 24px;
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC,sans-serif;
  font-weight: bold;
  color: rgba(0,0,0,0.8);
`

const Date = styled.div`
  margin-right: 50px;
  line-height: 24px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.6);
`

const Content = styled.span`
  line-height: 24px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
  a {
    color: #159bf3;
    text-decoration: none;
  }
`

const pageSize = 100
let page = 1

function formatMessageContent (content: string) {
  let html = content
  const teamPageRegex = /^(.*)\[(.*)]\(page:team:(.*)\)(.*)$/
  if (html.match(teamPageRegex)) {
    html = html.replace(teamPageRegex, "$1<a href='/team/?id=$3' target='_blank'>$2</a> $4")
  }
  return html;
}

const Message = () => {

  const [messages, setMessages] = useState([])
  const [total, setTotal] = useState(pageSize)

  const {run} = useRequest((p = 1) => {
    return jsonRequest(`/account/message?pageSize=${pageSize}&current=${p}`).then((rsp: any) => {
      setTotal(rsp.data.total)
      setMessages([...messages, ...rsp.data.data])
      return rsp.data.data
    })
  }, {manual: false})
  auth.authorize()

  const onChange = (ids) => {
    ids.forEach(id => {
      console.log(id)
      for (let i = 0; i < messages.length; i++) {
        const msg = messages[i]
        if (msg.id == id) {
          if (!msg.isRead) {
            jsonRequest(`/account/message/${id}`, {
              method: 'POST',
            }).then(() => {
              const newMessages = [...messages]
              newMessages[i].isRead = true
              setMessages(newMessages)
            })
          }
          break;
        }
      }
    })
  }

  return (<>
    <PersonalLayout>
      <PersonalBody
        title='消息中心'
      >
        <InfiniteScroll
          dataLength={messages.length}
          next={() => {
            run(++page)
          }}
          hasMore={messages.length < total}
          loader={
            <div style={{padding: '0 20px'}}>
              <Skeleton paragraph={{ rows: 1 }} active />
            </div>
          }
          // endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <Collapse
            expandIconPosition='end'
            ghost
            {...{onChange}}
          >
            {messages?.map(msg => (
              <Collapse.Panel
                key={msg.id}
                header={(
                  <div
                    // @ts-ignore
                    css={css`
                      position: relative;
                    `}
                  >
                    <Badge
                      status={msg.isRead ? "default" : "error"}
                      // @ts-ignore
                      css={css`
                        display: block;
                        position: absolute !important;
                        left: -16px;
                      `}
                    />
                    <Title>{msg.title}</Title>
                  </div>
                )}
                extra={(
                  <Date>{moment(msg.createdAt).format("YYYY-MM-DD HH:mm:ss")}</Date>
                )}
                // @ts-ignore
                css={css`
                  padding: 8px 0 8px 30px;
                  border-radius: 8px !important;
                  .ant-collapse-content-box, .ant-collapse-header {
                    padding: 8px !important;
                  }
                  .ant-collapse-content-box {
                    padding-top: 0 !important;
                  }
                  &:hover {
                    background-color: rgba(0,0,0,0.02);
                  }
                `}
              >
                <Content dangerouslySetInnerHTML={{__html: formatMessageContent(msg.content)}} />
              </Collapse.Panel>
            ))}
          </Collapse>
        </InfiniteScroll>
      </PersonalBody>
    </PersonalLayout>
  </>)
}

export default Message;

export const Head = () => <Seo />
